<template>
  <div class="wrap">
    <img :src="errorImg" alt="error" />
    <div class="desc">
      <h2>{{ code }}</h2>
      <p>{{ message }}</p>
      <div class="link">
        <router-link :to="link.url">
          <el-button type="primary" size="mini">{{ link.text }}</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    errorImg: {
      type: String,
      required: true
    },
    link: {
      type: Object,
      default() {
        return {
          text: "",
          url: ""
        };
      },
      required: true
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .desc {
    width: 260px;
    margin-left: 88px;
    h2 {
      color: #434e59;
      font-size: 72px;
      font-weight: 600;
      line-height: 72px;
      margin-bottom: 24px;
    }
    p {
      color: rgba(0, 0, 0, 0.45);
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
}
</style>
