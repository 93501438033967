<template>
  <m-error code="404" message="抱歉，你访问的页面不存在" :error-img="image404" :link="link"></m-error>
</template>

<script>
import errorTemplate from "@/components/error/Index";
import image404 from "@/assets/images/error/404.svg";
export default {
  data() {
    return {
      image404,
      link: {
        text: "返回首页",
        url: "/"
      }
    };
  },
  components: {
    "m-error": errorTemplate
  }
};
</script>
